@tailwind base;
@tailwind components;
@tailwind utilities;

::selection{
    background-color: #009ca6;
}

.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.grayscale:hover {
    -webkit-filter: grayscale(0%) !important;
    filter: none !important;
}

.container {
    max-width: 1280px
}